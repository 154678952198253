import "./styles.scss"

import React from "react"
import { withPrefix } from "gatsby"

const OrderDocs = ({ input }) => {
  return parseInt(input?.sharesTotal?.replace(",00 zł", "")) >= 30000 ? (
    input.productId === 1786 ? (
      // stałe
      <>
        <li>
          <a
            href={withPrefix(
              "/projekt-14/TIP14_powyzej_25_tys_stale/TIP14_umowa_poreczenia_stala_marza.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 1 - Umowa poręczenia
          </a>
        </li>
        <li>
          <a
            href={withPrefix(
              "/projekt-14/TIP14_powyzej_25_tys_stale/TIP14_formularz_podstawowych_informacji.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 2 - Formularz podstawowych informacji
          </a>
        </li>
        <li>
          <a
            href={withPrefix(
              "/projekt-14/TIP14_powyzej_25_tys_stale/TIP14_rygor_z_umowy_pozyczki_oprocentowanie_stale.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 3 - Rygor z umowy pożyczki
          </a>
        </li>
        <li>
          <a
            href={withPrefix(
              "/projekt-14/TIP14_powyzej_25_tys_stale/TIP14_rygor_z_umowy_poreczenia_oprocentowanie_stale.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 4 - Rygor z umowy poręczenia
          </a>
        </li>
      </>
    ) : (
      // zmienne
      <>
        <li>
          <a
            href={withPrefix(
              "/projekt-14/TIP14_pozyczka_powyzej_25_tys_zmienne/TIP14_umowa_poreczenia_pozyczki_pow_30_tys_zmienna_marza.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 1 - Umowa poręczenia
          </a>
        </li>
        <li>
          <a
            href={withPrefix(
              "/projekt-14/TIP14_pozyczka_powyzej_25_tys_zmienne/TIP14_formularz_podstawowych_informacji.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 2 - Formularz podstawowych informacji
          </a>
        </li>
        <li>
          <a
            href={withPrefix(
              "/projekt-14/TIP14_pozyczka_powyzej_25_tys_zmienne/TIP14_rygor_z_umowy_pozyczki_oprocentowanie_zmienne.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 3 - Rygor z umowy pożyczki
          </a>
        </li>
        <li>
          <a
            href={withPrefix(
              "/projekt-14/TIP14_pozyczka_powyzej_25_tys_zmienne/TIP14_rygor_z_umowy_poreczenia_oprocentowanie_zmienne.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 4 - Rygor z umowy poręczenia
          </a>
        </li>
      </>
    )
  ) : (
    // ponizej
    <>
      <li>
        <a
          href={withPrefix(
            "/projekt-14/TIP14_pozyczka_do_25_tys/TIP14_formularz_podstawowych_informacji.pdf"
          )}
          target="_blank"
          rel="noreferrer"
        >
          Załącznik nr 1 - FORMULARZ PODSTAWOWYCH INFORMACJI
        </a>
      </li>
    </>
  )
}

export default OrderDocs
