import "./styles.scss"

import React from "react"

const Payment = ({ input, setInput }) => {
  return (
    <section className="order-payment">
      <div className="container-fluid">
        <h3>Płatność</h3>
        <div className="order-payment__row">
          <button
            className={`order-payment__cta${
              input.paymentMethod === "bacs" ? " current" : ""
            }`}
            onClick={() => setInput({ ...input, paymentMethod: "bacs" })}
          >
            <span /> Przelew tradycyjny
          </button>
          {/* <button
            className={`order-payment__cta${
              input.paymentMethod === "tpay" ? " current" : ""
            }`}
            onClick={() => setInput({ ...input, paymentMethod: "tpay" })}
          >
            <span /> TPay
          </button> */}
        </div>
      </div>
    </section>
  )
}

export default Payment
