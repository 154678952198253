/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { useCookies } from "react-cookie"

import {
  Nav,
  BasicData,
  ContactData,
  // MeetingTime,
  Summary,
  Payment,
  Actions,
  Overlay,
} from "./components"

import { getFormattedCart } from "utils/functions"
import GET_CART from "queries/get-cart"

const Content = () => {
  const isBrowser = typeof window !== "undefined"
  const queryParameters = new URLSearchParams(
    isBrowser && window.location.search
  )
  const partnerParam = queryParameters.get("partner")
  const [cookies] = useCookies()

  const [step, setStep] = useState(1)
  const [pdf, setPdf] = useState(false)

  const [input, setInput] = useState({
    productId: null,
    partnerId: partnerParam
      ? partnerParam
      : cookies.cookie_partner_trust_finance
      ? cookies.cookie_partner_trust_finance
      : "",
    pdfFile: "",
    customID: "",
    clientType: "individual",
    firstName: "",
    secondFirstName: "",
    lastName: "",
    pesel: "",
    idNumber: "",
    phone: "",
    bankAccount: "",
    email: "",
    taxOffice: "",
    investor: "",
    krs: "",
    court: "",
    department: "",
    regon: "",
    nip: "",

    sharesQuantity: "",
    sharePrice: "100,00 zł",
    shareEmissionPrice: "",
    sharesTotal: "",
    sharesEmissionTotal: "",

    street: "",
    streetNumber: "",
    localNumber: "",
    city: "",
    postcode: "",
    municipality: "",
    county: "",
    province: "",

    isShippingAddress: true,
    shippingStreet: "",
    shippingStreetNumber: "",
    shippingLocalNumber: "",
    shippingCity: "",
    shippingPostcode: "",
    shippingMunicipality: "",
    shippingCounty: "",
    shippingProvince: "",

    country: "PL",
    paymentMethod: "",
    customerNote: "",
    errors: null,

    meetingCity: "",
    meetingDate: "",
    meetingHour: "",
    meetingDateOptional: "",
    meetingHourOptional: "",

    agent1Function: "",
    agent1Name: "",
    agent1Surname: "",
    agent1Pesel: "",
    agent2Function: "",
    agent2Name: "",
    agent2Surname: "",
    agent2Pesel: "",
    agent3Function: "",
    agent3Name: "",
    agent3Surname: "",
    agent3Pesel: "",
    agent4Function: "",
    agent4Name: "",
    agent4Surname: "",
    agent4Pesel: "",

    legalFirst: false,
    legalSecond: false,
  })

  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    pesel: "",
    idNumber: "",
    phone: "",
    bankAccount: "",
    email: "",
    taxOffice: "",
    investor: "",
    krs: "",
    court: "",
    department: "",
    regon: "",

    agent1Function: "",
    agent1Name: "",
    agent1Surname: "",
    agent1Pesel: "",

    street: "",
    streetNumber: "",
    city: "",
    postcode: "",
    province: "",
    shippingStreet: "",
    shippingStreetNumber: "",
    shippingCity: "",
    shippingPostcode: "",
    shippingProvince: "",

    meetingCity: "",
    meetingDate: "",
    meetingHour: "",

    legalFirst: false,
    legalSecond: false,
  })

  // 12345678901234567890123456 - bank
  // 12345678933 - pesel

  const handleValidationStepFirst = () => {
    const schema = {
      firstName:
        input.clientType === "individual" && input.firstName.length <= 2
          ? "error"
          : "",
      lastName:
        input.clientType === "individual" && input.lastName.length <= 2
          ? "error"
          : "",
      pesel:
        input.clientType === "individual" && input.pesel.length !== 11
          ? "error"
          : "",
      idNumber:
        input.clientType === "individual" && input.idNumber.length <= 2
          ? "error"
          : "",
      phone: input.phone.length <= 2 ? "error" : "",
      bankAccount: input.bankAccount.length !== 26 ? "error" : "",
      nip: input.nip.length !== 10 && input.nip.length > 0 ? "error" : "",
      taxOffice: input.taxOffice.length <= 2 ? "error" : "",
      investor:
        input.clientType === "company" && input.investor.length <= 2
          ? "error"
          : "",
      krs:
        input.clientType === "company" && input.krs.length <= 2 ? "error" : "",
      court:
        input.clientType === "company" && input.court.length <= 2
          ? "error"
          : "",
      department:
        input.clientType === "company" && input.department.length <= 2
          ? "error"
          : "",
      regon:
        input.clientType === "company" && input.regon.length <= 2
          ? "error"
          : "",

      agent1Function:
        input.clientType === "company" && input.agent1Function.length <= 2
          ? "error"
          : "",
      agent1Name:
        input.clientType === "company" && input.agent1Name.length <= 2
          ? "error"
          : "",
      agent1Surname:
        input.clientType === "company" && input.agent1Surname.length <= 2
          ? "error"
          : "",
      agent1Pesel:
        input.clientType === "company" && input.agent1Pesel.length <= 2
          ? "error"
          : "",
    }

    setValidationErrors({
      ...validationErrors,
      ...schema,
    })

    return !Object.values({ ...validationErrors, ...schema }).includes("error")
  }

  const handleValidationStepSecond = () => {
    const schema = {
      street: input.street.length <= 2 ? "error" : "",
      streetNumber: input.streetNumber.length < 1 ? "error" : "",
      city: input.city.length <= 2 ? "error" : "",
      postcode: input.postcode.length <= 2 ? "error" : "",
      province: input.province.length <= 2 ? "error" : "",

      shippingStreet:
        input.isShippingAddress && input.shippingStreet.length <= 2
          ? "error"
          : "",
      shippingStreetNumber:
        input.isShippingAddress && input.shippingStreetNumber.length < 1
          ? "error"
          : "",
      shippingCity:
        input.isShippingAddress && input.shippingCity.length <= 2
          ? "error"
          : "",
      shippingPostcode:
        input.isShippingAddress && input.shippingPostcode.length <= 2
          ? "error"
          : "",
      shippingProvince:
        input.isShippingAddress && input.shippingProvince.length <= 2
          ? "error"
          : "",
    }

    setValidationErrors({
      ...validationErrors,
      ...schema,
    })

    return !Object.values({ ...validationErrors, ...schema }).includes("error")
  }

  const handleValidationStepThird = () => {
    const schema = {
      meetingCity: input.meetingCity.length <= 2 ? "error" : "",
      meetingDate: input.meetingDate.length <= 2 ? "error" : "",
      meetingHour: input.meetingHour.length <= 2 ? "error" : "",
    }

    setValidationErrors({
      ...validationErrors,
      ...schema,
    })

    return !Object.values({ ...validationErrors, ...schema }).includes("error")
  }

  const handleValidationStepFourth = () => {
    const schema = {
      legalFirst: input.legalFirst === false ? "error" : "",
      legalSecond: input.legalSecond === false ? "error" : "",
    }

    setValidationErrors({
      ...validationErrors,
      ...schema,
    })

    return !Object.values({ ...validationErrors, ...schema }).includes("error")
  }

  // Get Cart Data
  const { data } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ cart }) => {
      const updatedCart = getFormattedCart(cart)
      setInput({ ...input })
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart))
    },
    onError: error => {
      console.warn(error)
    },
  })

  useEffect(() => {
    setInput({
      ...input,
      sharesQuantity: data?.cart?.contents?.nodes[0]?.quantity.toString(),
      shareEmissionPrice:
        data?.cart?.contents?.nodes[0]?.product?.node?.price.replace(
          "&nbsp;",
          " "
        ),
      sharesTotal: data?.cart?.total.replace("&nbsp;", " "),
      sharesEmissionTotal: data?.cart?.total.replace("&nbsp;", " "),
      productId: data?.cart?.contents?.nodes[0]?.product?.node?.databaseId,
    })
  }, [data?.cart?.contents?.nodes, data?.cart?.total, data?.cart?.isEmpty])

  const contentData = {
    data,
    input,
    setInput,
    step,
    setStep,
    pdf,
    setPdf,
    validationErrors,
    setValidationErrors,
    handleValidationStepFirst,
    handleValidationStepSecond,
    handleValidationStepThird,
    handleValidationStepFourth,
  }

  return (
    <>
      <Nav {...contentData} />
      {step === 1 && <BasicData {...contentData} />}
      {step === 2 && <ContactData {...contentData} />}
      {/* {step === 3 && <MeetingTime {...contentData} />} */}
      {step === 3 && <Summary {...contentData} />}
      {step === 4 && <Payment {...contentData} />}
      <Actions {...contentData} />
      {pdf && <Overlay />}
    </>
  )
}

export default Content
